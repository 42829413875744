const dev = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'saerp-licence-res-dev-attachmentsbucket-sg0xrvx0oaa8'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ypblxzmwq8.execute-api.us-east-1.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_I9MDoOyUh',
    APP_CLIENT_ID: '6avlcvtvpf9i9777n65f0vknd7',
    IDENTITY_POOL_ID: 'us-east-1:04300547-e53f-4371-af71-4197a09cf4ff'
  },
  MAX_ATTACHMENT_SIZE: 5000000,
}

const prod = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'saerp-licence-res-dev-attachmentsbucket-sg0xrvx0oaa8'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ypblxzmwq8.execute-api.us-east-1.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_I9MDoOyUh',
    APP_CLIENT_ID: '6avlcvtvpf9i9777n65f0vknd7',
    IDENTITY_POOL_ID: 'us-east-1:04300547-e53f-4371-af71-4197a09cf4ff'
  },
  MAX_ATTACHMENT_SIZE: 5000000,
}

export default process.env.REACT_APP_STAGE === 'prod' ? { ...prod } : { ...dev }