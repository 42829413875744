import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import createSagaMiddleware from 'redux-saga'
import {
  rootReducer,
  initialState
} from './reducers';
import mySaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('saerplicstate', serializedState);
  } catch (err) {
    console.log('erro ao salvar no localStorage', err);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('saerplicstate');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

export const configureStore = () => {
  const store = createStore(
    rootReducer,
    Object.assign({}, initialState,
      persistedState),
    enhancer
  );
  sagaMiddleware.run(mySaga)
  store.subscribe(() => saveToLocalStorage(store.getState()));
  return store;
}


export default configureStore;