import * as types from './types'

export const fetchClients = () => {
  return {
    type: types.CLIENTS_FETCH_REQUESTED
  }
}

export const setClient = ({ client }) => {
  return {
    type: types.SETCLIENT,
    payload: client
  }
}

export const fetchClient = ({ clientId }) => {
  return {
    type: types.CLIENT_FETCH_REQUESTED,
    clientId
  }
}

export const saveClient = ({ client }) => {
  return {
    type: types.CLIENT_SAVE_REQUESTED,
    payload: client
  }
}