import React from 'react'
import { Button } from 'react-bootstrap'
import './LoaderButton.css'

export default ({ disabled, isLoading, className, ...props }) => {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}>
            <span className={`icon ${isLoading ? 'd-inline' : 'd-none'}`}><i className="fas fa-sync fa-spin" /></span>
            {props.children}
        </Button>
    )
}