import { call, put, takeLatest } from 'redux-saga/effects'
import { API } from 'aws-amplify'

import * as types from './types'

const apiName = 'saerplic'

function apiClients() {
  return API.get(apiName, '/regs/CLIENT', {})
}

function getClient(regId) {
  return API.get(apiName, `/reg/CLIENT:${regId}`, {})
}

// worker Saga: will be fired on CLIENTS_FETCH_REQUESTED actions
function* fetchClients(action) {
  try {
    console.log('fetching clients')
    const clients = yield call(apiClients)
    console.log('fetched clients', clients)
    yield put({
      type: types.CLIENTS_FETCH_SUCESS,
      clients
    })
  } catch (e) {
    console.log('failled to fetch clients', e)
    yield put({ type: types.CLIENTS_FETCH_FAILED, message: e.message })
  }
}

// worker for fetch client
function* fetchClient(action) {
  try {
    console.log('client fetching', action.clientId)
    const client = yield call(getClient, action.clientId)
    yield put({
      type: types.CLIENT_FETCH_SUCESS,
      client
    })
    console.log('client fetched', client)
  } catch (e) {
    console.log('failed to fetch client', e)
    yield put({ type: types.CLIENT_FETCH_FAILED, message: e.message })
  }
}

// Save the client worker
function* saveClient(action) {
  try {
    const clientSave = action.payload
    if (clientSave.regId) {
      // Update the client
      clientSave.regType = 'CLIENT'
      console.log('updating the client', clientSave)
      yield call(() => API.put(apiName, `/reg/CLIENT:${clientSave.regId}`, { body: clientSave }))
      clientSave.version += 1
      yield put({
        type: types.CLIENT_SAVE_SUCESS,
        client: clientSave
      })
    } else {
      // Create the client
      console.log('created client', clientSave)
      clientSave.regType = 'CLIENT'
      const client = yield call(() => API.post(apiName, '/reg', { body: clientSave }))
      yield put({
        type: types.CLIENT_SAVE_SUCESS,
        client
      })
    }
  } catch (e) {
    console.log('failed to save client', e)
    yield put({ type: types.CLIENT_SAVE_FAILED, message: e.message })
  }
}

// worker Saga: will be fired on USERS_FETCH_REQUESTED actions
function* fetchUsers(action) {
  try {
    console.log('fetching users')
    const users = yield call(() => API.get(apiName, '/user'))
    console.log('fetched users', users)
    yield put({
      type: types.USERS_FETCH_SUCESS,
      users
    })
  } catch (e) {
    console.log('failled to fetch users', e)
    yield put({ type: types.USERS_FETCH_FAILED, message: e.message })
  }
}

function* createUser(action) {
  try {
    console.log('Creating user')
    const user = yield call(() => API.post(apiName, '/user', { body: action.payload }))
    yield put({
      type: types.USER_SAVE_SUCESS,
      user
    })
    if (typeof action.onFinish === 'function') {
      action.onFinish()
    }
  } catch (error) {
    console.log('failed to create user', error)
    yield put({ type: types.USER_SAVE_FAILED, message: error.message })
  }
}

function* deactivateUser(action) {
  try {
    console.log('Deactivating user')
    const user = yield call(() => API.del(apiName, `/user/${action.payload}`))
    yield put({
      type: types.USER_DEACTIVATE_SUCESS,
      user
    })
    if (typeof action.onFinish === 'function') {
      action.onFinish()
    }
  } catch (error) {
    console.log('failed to deactivated user', error)
    yield put({ type: types.USER_DEACTIVATE_FAILED, message: error.message })
  }
}

/*
  Starts fetchUser on each dispatched `CLIENTS_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
/*
function* mySaga() {
  yield takeEvery("CLIENTS_FETCH_REQUESTED", fetchClients);
}*/

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "CLIENTS_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
  yield takeLatest(types.CLIENTS_FETCH_REQUESTED, fetchClients)
  yield takeLatest(types.CLIENT_FETCH_REQUESTED, fetchClient)
  yield takeLatest(types.CLIENT_SAVE_REQUESTED, saveClient)

  yield takeLatest(types.USERS_FETCH_REQUESTED, fetchUsers)
  yield takeLatest(types.USER_SAVE_REQUESTED, createUser)
  yield takeLatest(types.USER_DEACTIVATE_REQUESTED, deactivateUser)
}

export default mySaga