import * as types from './types'

export const initialState = {
  isAuthenticated: false
}

export const reducer = (state = initialState, action) => {
  if (action.type === types.SETAUTHENTICATED) {
    return Object.assign({}, state, {
      isAuthenticated: action.payload
    })
  }
  return state
}
