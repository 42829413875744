// Clients Types
export const SETCLIENTS = 'SETCLIENTS'
export const SETCLIENT = 'SETCLIENT'
export const CLIENTS_FETCH_REQUESTED = 'CLIENTS_FETCH_REQUESTED'
export const CLIENTS_FETCH_SUCESS = 'CLIENTS_FETCH_SUCESS'
export const CLIENTS_FETCH_FAILED = 'CLIENTS_FETCH_FAILED'
export const CLIENT_FETCH_REQUESTED = 'CLIENT_FETCH_REQUESTED'
export const CLIENT_FETCH_SUCESS = 'CLIENT_FETCH_SUCESS'
export const CLIENT_FETCH_FAILED = 'CLIENT_FETCH_FAILED'
export const CLIENT_SAVE_REQUESTED = 'CLIENT_SAVE_REQUESTED'
export const CLIENT_SAVE_SUCESS = 'CLIENT_SAVE_SUCESS'
export const CLIENT_SAVE_FAILED = 'CLIENT_SAVE_FAILED'

// Users Types
export const SETUSERS = 'SETUSERS'
export const SETUSER = 'SETUSER'
export const USERS_FETCH_REQUESTED = 'USERS_FETCH_REQUESTED'
export const USERS_FETCH_SUCESS = 'USERS_FETCH_SUCESS'
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED'
export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED'
export const USER_FETCH_SUCESS = 'USER_FETCH_SUCESS'
export const USER_FETCH_FAILED = 'USER_FETCH_FAILED'
export const USER_SAVE_REQUESTED = 'USER_SAVE_REQUESTED'
export const USER_SAVE_SUCESS = 'USER_SAVE_SUCESS'
export const USER_SAVE_FAILED = 'USER_SAVE_FAILED'
export const USER_DEACTIVATE_REQUESTED = 'USER_DEACTIVATE_REQUESTED'
export const USER_DEACTIVATE_SUCESS = 'USER_DEACTIVATE_SUCESS'
export const USER_DEACTIVATE_FAILED = 'USER_DEACTIVATE_FAILED'

// Login types
export const SETAUTHENTICATED = 'SETAUTHENTICATED'