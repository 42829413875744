import React, { Component } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'

import { setAuthenticated } from '../redux/loginActions'
import LoaderButton from '../components/LoaderButton'

import logo from '../assets/SAERP.png'

class ForgotPassword extends Component {
  state = {
    email: '',
    senha: '',
    senhaConfirm: '',
    confirmationCode: '',
    isLoading: false,
    secondStep: false
  }

  handleRequestEmail = async () => {
    this.setState({
      isLoading: true
    })

    try {
      await Auth.forgotPassword(this.state.email)
      this.setState({
        isLoading: false,
        secondStep: true
      })
    } catch (e) {
      console.log(e)
      this.setState({
        isLoading: false,
        errorString: 'Erro ao recuperar senha, verifique seu usuario e tente novamente!'
      })
    }
  }

  handleFinishChange = async () => {
    this.setState({
      isLoading: true
    })

    try {
      await Auth.forgotPasswordSubmit(this.state.email, this.state.confirmationCode, this.state.senha)
      this.setState({
        isLoading: false,
        secondStep: true
      })
      if (typeof this.props.finishedForgot === 'function') {
        this.props.finishedForgot()
      }
    } catch (e) {
      console.log(e)
      this.setState({
        isLoading: false,
        errorString: 'Erro ao recuperar senha, verifique seu usuario e tente novamente!'
      })
    }
  }

  renderRequestPage = () => {
    const validations = [
      this.state.email && this.state.email.length > 0 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+.*/.test(this.state.email)
    ]

    const isValid = validations.reduce((a, p) => a && p)
    return (
      <Row>
        <Form.Group as={Col} className="px-5" controlId="email">
          <Form.Control
            type="email"
            autoFocus
            placeholder="Email"
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })} />
        </Form.Group>
        <Col>
          <LoaderButton
            onClick={this.handleRequestEmail}
            disabled={!isValid}
            isLoading={this.state.isLoading}
            className="float-right px-5">
            Recuperar senha
                      </LoaderButton>
        </Col>
      </Row>
    )
  }

  renderChangePasswordPage = () => {
    const validations = [
      this.state.email && this.state.email.length > 0 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+.*/.test(this.state.email),
      this.state.confirmationCode && this.state.confirmationCode.length > 2,
      this.state.senha && this.state.senha.length > 6,
      this.state.senha === this.state.senhaConfirm
    ]

    const isValid = validations.reduce((a, p) => a && p)

    return (
      <>
        <Row>
          <Form.Group as={Col} className="px-5" controlId="email">
            <Form.Control
              type="tel"
              autoFocus
              placeholder="Código de confirmação que voce recebeu no email"
              value={this.state.confirmationCode}
              onChange={e => this.setState({ confirmationCode: e.target.value })} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="px-5 mb-5" controlId="senha">
            <Form.Control
              type="password"
              placeholder="Nova Senha"
              value={this.state.senha}
              onChange={e => this.setState({ senha: e.target.value })} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="px-5 mb-5" controlId="senhaConfirm">
            <Form.Control
              type="password"
              placeholder="Confirmação Senha"
              value={this.state.senhaConfirm}
              onChange={e => this.setState({ senhaConfirm: e.target.value })} />
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <LoaderButton
              onClick={this.handleFinishChange}
              disabled={!isValid}
              isLoading={this.state.isLoading}
              className="float-right px-5">
              Confirmar
                      </LoaderButton>
          </Col>
        </Row>
      </>
    )
  }

  render() {

    return (
      <>
        <Row>
          <Col>
            <div className="w-50 p-3 bg-secondary text-light ml-auto mr-auto mt-5" style={{ borderRadius: '10px' }}>
              <Row>
                <Col className="lead text-center mb-5 mt-3">
                  <img src={logo} alt="SAERP" style={{ width: '127px', height: '43px' }} />
                </Col>
              </Row>
              {!!this.state.errorString ? <Row>
                <Col className="alert alert-danger">
                  {this.state.errorString}
                </Col>
              </Row> : null}
              {this.state.secondStep ? this.renderChangePasswordPage() : this.renderRequestPage()}
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.login.isAuthenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAuthenticated: isAuthenticated => dispatch(setAuthenticated({ isAuthenticated }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)