import React from 'react'
import { Link } from '@reach/router'
import { Nav } from 'react-bootstrap'

const MenuLink = ({ to, className, location, ...props }) =>
  <Link to={to} className={`${className} ${location.pathname === '/' ? 'bg-light' : ''}`} {...props} />


export default props => {
  console.log('pathname', props.location.pathname)
  return (
    <Nav defaultActiveKey="/"
      className="flex-column navbar-dark bg-dark min-vh-100"
      navbar>
      <Nav.Item className="text-light m-0 p-2 text-center text-wrap">
        SAERP - Gestor de licenças
      </Nav.Item>
      <MenuLink to="/" className="nav-link" location={props.location}>
        Home
            </MenuLink>
      <MenuLink to="/Clients" className="nav-link" location={props.location}>
        Clientes
            </MenuLink>
      <MenuLink to="/Users" className="nav-link" location={props.location}>
        Usuarios
            </MenuLink>
      <Nav.Link className="text-light m-0 p-2 text-center text-wrap"
        style={{ alignSelf: 'flex-end' }}
        onClick={props.onLogout}>Sair</Nav.Link>
    </Nav>
  )
}
