import React, { Component } from 'react'
import { Row, Col, Button, ButtonGroup, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from '@reach/router'

import { fetchClients } from '../redux/clientsActions'

class Clients extends Component {
  state = {}

  componentDidMount() {
    this.props.fetchClients() // Fetch the clients list
  }

  renderClients = () => {
    if (!this.props.clients || !this.props.clients.items || this.props.clients.items.length === 0) {
      return <tr>
        <td colSpan="5" className="text-center">Nenhum dado</td>
      </tr>
    }

    return this.props.clients.items.map(client => <tr key={`${client.regId}`}>
      <td>{client.razao_social}</td>
      <td>{client.cnpj}</td>
      <td>{client.telefone}</td>
      <td>{client.email}</td>
      <td>
        <ButtonGroup>
          <Link className="btn btn-secondary" to={`/form/Client/${client.regId}`}>
            <i className="fas fa-pen" />
          </Link>
          <Button variant="danger">
            <i className="fas fa-trash" />
          </Button>
        </ButtonGroup>
      </td>
    </tr>)
  }

  render() {
    return (<>
      <Row>
        <Col>
          <h1>Clientes</h1>
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Link className="btn btn-primary" to="/form/Client/novo">
            <i className="fas fa-plus" /> Novo
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped hover>
            <thead>
              <tr>
                <th>Raz&atilde;o Social</th>
                <th>CNPJ</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {this.renderClients()}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
    );
  }
}

const mapStateToProps = state => {
  return {
    clients: state.clients.clients,
    isSaving: state.clients.isSaving
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchClients: () => dispatch(fetchClients())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
