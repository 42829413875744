import React, { Component } from 'react'
import { Row, Col, ButtonGroup, Table, Form, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'

import { fetchUsers, createUser, deactivateUser } from '../redux/usersActions'
import LoaderButton from '../components/LoaderButton'

class Users extends Component {
  state = {
    usuario: '',
    senha: ''
  }

  componentDidMount() {
    this.props.fetchUsers() // Fetch the user list
  }

  mapUserAttributes = user => {
    for (let attr of user.Attributes) {
      user[attr.Name] = attr.Value
    }
    return user
  }

  handleIncluirUsuario = async () => {
    this.props.createUser({
      email: this.state.usuario,
      password: this.state.senha
    }, () => {
      this.setState({
        usuario: '',
        senha: ''
      })
      this.props.fetchUsers()
    })
  }

  renderUsers = () => {
    if (!this.props.users || !this.props.users.Users || this.props.users.Users.length === 0) {
      return <tr>
        <td colSpan="5" className="text-center">Nenhum dado</td>
      </tr>
    }

    return this.props.users.Users.map(userraw => {
      const user = this.mapUserAttributes(userraw)
      if (user.Enabled === false) {
        return null
      }
      return <tr key={`${user.Username}`}>
        <td>{user.email}</td>
        <td>
          {user.email === 'gabriel@gabrimar.com.br' ? null : <ButtonGroup>
            <LoaderButton
              disabled={false}
              isLoading={this.props.isSaving}
              onClick={() => this.props.deactivateUser(user.Username, () => this.props.fetchUsers())}
              variant="danger">
              <i className="fas fa-trash" />
            </LoaderButton>
          </ButtonGroup>}
        </td>
      </tr>
    })
  }

  render() {
    const validations = [
      this.state.usuario && this.state.usuario.length > 0 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+.*/.test(this.state.usuario),
      this.state.senha && this.state.senha.length > 6
    ]

    const isValid = validations.reduce((a, p) => a && p)

    return (<>
      <Row>
        <Col>
          <h1>Usuários</h1>
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} className="px-3" controlId="usuario">
          <Form.Control
            type="email"
            autoFocus
            placeholder="Email"
            value={this.state.usuario}
            onChange={e => this.setState({ usuario: e.target.value })} />
        </Form.Group>
        <Form.Group as={Col} className="px-3" controlId="senha">
          <Form.Control
            type="password"
            placeholder="Senha Temporária"
            value={this.state.senha}
            onChange={e => this.setState({ senha: e.target.value })} />
        </Form.Group>
        <Col className="px-3">
          <LoaderButton
            disabled={!isValid}
            isLoading={this.props.isSaving}
            onClick={this.handleIncluirUsuario}
            className="px-5">
            <i className="fas fa-plus" /> Incluir
                        </LoaderButton>
        </Col>
      </Row>
      {this.props.lastError ? <Row>
        <Col>
          <Alert variant="danger">{this.props.lastError}</Alert>
        </Col>
      </Row> : null}
      {this.props.lastSuccess ? <Row>
        <Col>
          <Alert variant="success">{this.props.lastSuccess}</Alert>
        </Col>
      </Row> : null}
      <Row>
        <Col>
          <Table striped hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {this.renderUsers()}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    isSaving: state.users.isSaving,
    lastError: state.users.lastError,
    lastSuccess: state.users.lastSuccess
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    createUser: (user, onFinish) => dispatch(createUser({ user, onFinish })),
    deactivateUser: (userId, onFinish) => dispatch(deactivateUser({ userId, onFinish }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
