import * as types from './types'

export const initialState = {
  clients: {},
  client: {},
  lastError: '',
  lastSuccess: '',
  isSaving: false
}

export const reducer = (state = initialState, action) => {
  if (action.type === types.SETCLIENTS) {
    return Object.assign({}, state, {
      clients: action.payload,
      lastError: ''
    })
  }
  if (action.type === types.SETCLIENT) {
    return Object.assign({}, state, {
      client: action.payload,
      lastError: ''
    })
  }
  if (action.type === types.CLIENT_SAVE_REQUESTED) {
    return Object.assign({}, state, {
      lastSuccess: '',
      isSaving: true
    })
  }
  if (action.type === types.CLIENT_SAVE_SUCESS) {
    return Object.assign({}, state, {
      client: action.client,
      isSaving: false,
      lastSuccess: 'Sucesso ao salvar o cliente',
      lastError: ''
    })
  }
  if (action.type === types.CLIENT_SAVE_FAILED) {
    return Object.assign({}, state, {
      isSaving: false,
      lastError: action.message
    })
  }
  if (action.type === types.CLIENT_FETCH_SUCESS) {
    return Object.assign({}, state, {
      client: action.client,
      isSaving: false,
      lastSuccess: '',
      lastError: ''
    })
  }
  if (action.type === types.CLIENTS_FETCH_SUCESS) {
    console.log('action success', action)
    return Object.assign({}, state, {
      clients: action.clients,
      lastError: ''
    })
  }
  return state
}
