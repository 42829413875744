import * as clients from './clients'
import * as login from './login'
import * as users from './users'
import {
  combineReducers
} from 'redux'

// Initial (starting) state
export const initialState = {
  clients: clients.initialState,
  login: login.initialState,
  users: users.initialState
}

// Our root reducer starts with the initial state
// and must return a representation of the next state
export const appReducer = combineReducers({
  clients: clients.reducer,
  login: login.reducer,
  users: users.reducer
})

export const rootReducer = (state, action) => {
  /*
  if (action.type === types.SETTOKEN && action.payload === null) {
      state = undefined
      saveToLocalStorage({})
  }*/
  return appReducer(state, action)
}

export default rootReducer