import React, { Component } from 'react'
import { Row, Col, Form, Table, Button, Modal, ButtonGroup, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'

import { setClient, fetchClient, saveClient } from '../redux/clientsActions'
import LoadingCircle from '../components/LoadingCircle'
import LoaderButton from '../components/LoaderButton'

const novoCliente = {
  razao_social: '',
  cnpj: '',
  telefone: '',
  email: '',
  licencas: []
}

const novaLicenca = {
  licenca: '',
  servidor: '',
  banco: '',
  usuarios: 0,
  empresas: 0,
  status: true
}

class Client extends Component {
  state = {
    isLicenceEdit: false,
    licenceEdit: -1
  }

  async componentDidMount() {
    try {
      console.log('fetching client id', this.props.id)
      if (this.props.id === 'novo') {
        this.props.setClient(novoCliente)
      } else {
        this.props.fetchClient(this.props.id)
      }
    } catch (e) {
      console.log('error on load client')
    }
  }

  handleSave = e => {
    e.preventDefault()
    this.props.saveClient(this.props.client)
  }

  setClient = clientToMerge => {
    if (!clientToMerge) {
      return
    }
    return this.props.setClient(Object.assign({}, this.props.client, clientToMerge))
  }

  setLicence = licenceToMerge => {
    if (!licenceToMerge) {
      return
    }
    const licenceObj = Object.assign({}, this.props.client.licencas[this.state.licenceEdit], licenceToMerge)
    const licencas = [...this.props.client.licencas]
    licencas[this.state.licenceEdit] = licenceObj
    this.setClient({
      licencas
    })
  }

  addLicence = () => {
    const licencas = [...this.props.client.licencas, novaLicenca]
    this.setClient({
      licencas
    })
    this.setState({
      licenceEdit: licencas.length - 1,
      isLicenceEdit: true
    })
  }

  editLicence = indLicence => {
    if (indLicence >= 0 && indLicence < this.props.client.licencas.length) {
      console.log('editing licence')
      this.setState({
        licenceEdit: indLicence,
        isLicenceEdit: true
      })
    }
  }

  removeLicence = indLicence => {
    if (indLicence >= 0 && indLicence < this.props.client.licencas.length) {
      const licencas = [...this.props.client.licencas]
      licencas.splice(indLicence, 1)
      this.setClient({
        licencas
      })
    }
  }

  renderLicencas = () => {
    if (!this.props.client || !this.props.client.licencas || this.props.client.licencas === 0) {
      return null;
    }
    return this.props.client.licencas.map((licenca, i) => (<tr key={`${i}`}>
      <td>{licenca.licenca}</td>
      <td>{licenca.banco}</td>
      <td>{licenca.servidor}</td>
      <td>{licenca.usuarios}</td>
      <td>{licenca.empresas}</td>
      <td>{licenca.status ? 'Ativa' : 'Desativado'}</td>
      <td>
        <ButtonGroup>
          <Button onClick={() => this.editLicence(i)}>
            <i className="fas fa-pen" />
          </Button>
          <Button onClick={() => this.removeLicence(i)} variant="danger">
            <i className="fas fa-trash" />
          </Button>
        </ButtonGroup>
      </td>
    </tr>))
  }

  renderLicForm = () => {
    if (this.state.licenceEdit < 0 || this.state.licenceEdit >= this.props.client.licencas.length) {
      return null;
    }
    const licenca = this.props.client.licencas[this.state.licenceEdit]
    return (<>
      <Row>
        <Form.Group as={Col} id="licenca">
          <Form.Label>Licen&ccedil;a</Form.Label>
          <Form.Control
            value={licenca.licenca}
            onChange={e => this.setLicence({ licenca: e.target.value })}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} id="servidor">
          <Form.Label>Servidor</Form.Label>
          <Form.Control
            value={licenca.servidor}
            onChange={e => this.setLicence({ servidor: e.target.value })}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} id="banco">
          <Form.Label>Banco</Form.Label>
          <Form.Control
            value={licenca.banco}
            onChange={e => this.setLicence({ banco: e.target.value })}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} id="empresas">
          <Form.Label>Empresas</Form.Label>
          <Form.Control
            value={licenca.empresas}
            type="number"
            onChange={e => this.setLicence({ empresas: e.target.value })}
          />
        </Form.Group>
        <Form.Group as={Col} id="usuarios">
          <Form.Label>Usuarios</Form.Label>
          <Form.Control
            value={licenca.usuarios}
            type="number"
            onChange={e => this.setLicence({ usuarios: e.target.value })}
          />
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <Form.Check
            type="switch"
            id="status"
            checked={licenca.status === true}
            onChange={() => this.setLicence({ status: !licenca.status })}
            label="Ativo" />
        </Col>
      </Row>
    </>)
  }

  renderResult = () => {
    if (this.props.lastError) {
      return <Row>
        <Col>
          <Alert variant="danger">
            <Alert.Heading>Ops! Ocorreu um erro ao salvar!</Alert.Heading>
            <p>
              {this.props.lastError}
            </p>
          </Alert>
        </Col>
      </Row>
    }
    if (this.props.lastSuccess) {
      return <Row>
        <Col>
          <Alert variant="success">
            <Alert.Heading>Sucesso!</Alert.Heading>
            <p>
              {this.props.lastSuccess}
            </p>
          </Alert>
        </Col>
      </Row>
    }
    return null
  }

  render() {
    if (!this.props.client) {
      return <LoadingCircle />
    }

    const validations = [
      this.props.client.razao_social && this.props.client.razao_social.length > 3,
      this.props.client.cnpj && this.props.client.cnpj.length > 10
    ]

    const isValid = validations.reduce((a, p) => a && p)
    return (
      <>
        <Modal
          show={this.state.isLicenceEdit}
          size="lg"
          onHide={() => this.setState({ isLicenceEdit: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Editar licença</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderLicForm()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ isLicenceEdit: false })}>Concluir</Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            <h2>Editar Cliente</h2>
          </Col>
        </Row>
        {this.renderResult()}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              {this.props.client.regId ? <Row><Col><h3>Chave do cliente: {this.props.client.regId}</h3></Col></Row> : null}
              <Row>
                <Form.Group as={Col} id="razao_social">
                  <Form.Label>Raz&atilde;o Social</Form.Label>
                  <Form.Control
                    value={this.props.client.razao_social}
                    onChange={e => this.setClient({ razao_social: e.target.value })}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} id="cnpj">
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control
                    value={this.props.client.cnpj}
                    onChange={e => this.setClient({ cnpj: e.target.value })}
                  />
                </Form.Group>
                <Form.Group as={Col} id="telefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    value={this.props.client.telefone}
                    onChange={e => this.setClient({ telefone: e.target.value })}
                  />
                </Form.Group>
                <Form.Group as={Col} id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={this.props.client.email}
                    onChange={e => this.setClient({ email: e.target.value })}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <h3>Licen&ccedil;as</h3>
                </Col>
              </Row>
              <Row>
                <Col className="my-3">
                  <Button onClick={this.addLicence}>Incluir Licen&ccedil;a</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Licen&ccedil;a</th>
                        <th>Banco</th>
                        <th>Servidor</th>
                        <th>Usuarios</th>
                        <th>Empresas</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderLicencas()}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoaderButton
                    type="submit"
                    disabled={!isValid}
                    isLoading={this.props.isSaving}
                    className="float-right px-5">
                    Salvar
                      </LoaderButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    client: state.clients.client,
    isSaving: state.clients.isSaving,
    lastError: state.clients.lastError,
    lastSuccess: state.clients.lastSuccess
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setClient: client => dispatch(setClient({ client })),
    fetchClient: clientId => dispatch(fetchClient({ clientId })),
    saveClient: client => dispatch(saveClient({ client }))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Client)