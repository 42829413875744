import * as types from './types'

export const initialState = {
  users: {},
  user: {},
  lastError: '',
  lastSuccess: '',
  isSaving: false
}

export const reducer = (state = initialState, action) => {
  if (action.type === types.SETUSERS) {
    return Object.assign({}, state, {
      users: action.payload,
      lastError: ''
    })
  }
  if (action.type === types.SETUSER) {
    return Object.assign({}, state, {
      user: action.payload,
      lastError: ''
    })
  }
  if (action.type === types.USER_SAVE_REQUESTED) {
    return Object.assign({}, state, {
      lastSuccess: '',
      isSaving: true
    })
  }
  if (action.type === types.USER_DEACTIVATE_REQUESTED) {
    return Object.assign({}, state, {
      lastSuccess: '',
      lastError: '',
      isSaving: true
    })
  }
  if (action.type === types.USER_SAVE_SUCESS) {
    return Object.assign({}, state, {
      user: action.user,
      isSaving: false,
      lastSuccess: 'Sucesso ao salvar o usuario',
      lastError: ''
    })
  }
  if (action.type === types.USER_DEACTIVATE_SUCESS) {
    return Object.assign({}, state, {
      user: action.user,
      isSaving: false,
      lastSuccess: 'Sucesso ao remover o usuario',
      lastError: ''
    })
  }
  if (action.type === types.USER_SAVE_FAILED) {
    return Object.assign({}, state, {
      isSaving: false,
      lastError: action.message
    })
  }
  if (action.type === types.USER_DEACTIVATE_FAILED) {
    return Object.assign({}, state, {
      isSaving: false,
      lastSuccess: '',
      lastError: action.message
    })
  }
  if (action.type === types.USER_FETCH_SUCESS) {
    return Object.assign({}, state, {
      user: action.user,
      isSaving: false,
      lastSuccess: '',
      lastError: ''
    })
  }
  if (action.type === types.USERS_FETCH_SUCESS) {
    console.log('action success', action)
    return Object.assign({}, state, {
      users: action.users,
      lastError: ''
    })
  }
  return state
}
