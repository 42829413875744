import * as types from './types'

export const fetchUsers = () => {
  return {
    type: types.USERS_FETCH_REQUESTED
  }
}

export const setUser = ({ user }) => {
  return {
    type: types.SETUSER,
    payload: user
  }
}

export const fetchUser = ({ userId }) => {
  return {
    type: types.USER_FETCH_REQUESTED,
    userId
  }
}

export const createUser = ({ user, onFinish }) => {
  return {
    type: types.USER_SAVE_REQUESTED,
    payload: user,
    onFinish
  }
}

export const deactivateUser = ({ userId, onFinish }) => {
  return {
    type: types.USER_DEACTIVATE_REQUESTED,
    payload: userId,
    onFinish
  }
}