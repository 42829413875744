import React, { Component } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'

import { setAuthenticated } from '../redux/loginActions'
import LoaderButton from '../components/LoaderButton'

import logo from '../assets/SAERP.png'
import ForgotPassword from './ForgotPassword'

class Login extends Component {
  state = {
    usuario: '',
    senha: '',
    isLoading: false,
    isForgotten: false,
    novaSenhaRequerida: false,
    novaSenhaUser: false,
    novaSenha: '',
    novaSenhaConfirm: '',
  }

  handleNewPasswordRequest = async () => {
    this.setState({
      isLoading: true
    })

    try {
      await Auth.completeNewPassword(this.state.novaSenhaUser, this.state.novaSenha)
      this.props.setAuthenticated(true)
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false, errorString: 'Nova Senha é inválida' })
    }
  }

  handleLogin = async e => {
    e.preventDefault()
    this.setState({
      isLoading: true
    })

    try {
      const user = await Auth.signIn(this.state.usuario, this.state.senha)
      console.log('usuario', user)
      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({
          novaSenhaRequerida: true,
          novaSenhaUser: user,
          isLoading: false
        })
      } else {
        this.props.setAuthenticated(true)
      }
      //this.props.setAuthenticated(true)
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false, errorString: 'Usuário ou Senha inválido' })
    }
  }

  handleForgot = () => {
    this.setState({
      isForgotten: true
    })
  }

  renderNewPassRequired = () => {
    const validations = [
      this.state.novaSenha && this.state.novaSenha.length > 6,
      this.state.novaSenha === this.state.novaSenhaConfirm
    ]

    const isValid = validations.reduce((a, p) => a && p)

    return <>
      <Row>
        <Col>
          <div className="w-50 p-3 bg-secondary text-light ml-auto mr-auto mt-5" style={{ borderRadius: '10px' }}>
            <Row>
              <Col className="lead text-center mb-5 mt-3">
                <img src={logo} alt="SAERP" style={{ width: '127px', height: '43px' }} />
              </Col>
            </Row>
            {!!this.state.errorString ? <Row>
              <Col className="alert alert-danger">
                {this.state.errorString}
              </Col>
            </Row> : null}
            <Form onSubmit={this.handleNewPasswordRequest}>
              <Row>
                <h2>Nova senha requerida para continuar</h2>
              </Row>
              <Row>
                <Form.Group as={Col} className="px-5 mb-5" controlId="novaSenha">
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    value={this.state.novaSenha}
                    onChange={e => this.setState({ novaSenha: e.target.value })} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="px-5 mb-5" controlId="novaSenhaConfirm">
                  <Form.Control
                    type="password"
                    placeholder="Confirmação da Senha"
                    value={this.state.novaSenhaConfirm}
                    onChange={e => this.setState({ novaSenhaConfirm: e.target.value })} />
                </Form.Group>
              </Row>
              <Row>
                <Col className="mb-4 mr-5">
                  <LoaderButton
                    type="submit"
                    disabled={!isValid}
                    isLoading={this.state.isLoading}
                    className="float-right px-5">
                    Continuar Login
                      </LoaderButton>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  }

  render() {

    if (this.state.isForgotten) {
      return <ForgotPassword finishedForgot={() => this.setState({ isForgotten: false })} />
    }

    if (this.state.novaSenhaRequerida) {
      return this.renderNewPassRequired()
    }

    const validations = [
      this.state.usuario && this.state.usuario.length > 0 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+.*/.test(this.state.usuario),
      this.state.senha && this.state.senha.length > 6
    ]

    const isValid = validations.reduce((a, p) => a && p)

    return (
      <>
        <Row>
          <Col>
            <div className="w-50 p-3 bg-secondary text-light ml-auto mr-auto mt-5" style={{ borderRadius: '10px' }}>
              <Row>
                <Col className="lead text-center mb-5 mt-3">
                  <img src={logo} alt="SAERP" style={{ width: '127px', height: '43px' }} />
                </Col>
              </Row>
              {!!this.state.errorString ? <Row>
                <Col className="alert alert-danger">
                  {this.state.errorString}
                </Col>
              </Row> : null}
              <Form onSubmit={this.handleLogin}>
                <Row>
                  <Form.Group as={Col} className="px-5" controlId="usuario">
                    <Form.Control
                      type="email"
                      autoFocus
                      placeholder="Email"
                      value={this.state.usuario}
                      onChange={e => this.setState({ usuario: e.target.value })} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="px-5 mb-5" controlId="senha">
                    <Form.Control
                      type="password"
                      placeholder="Senha"
                      value={this.state.senha}
                      onChange={e => this.setState({ senha: e.target.value })} />
                  </Form.Group>
                </Row>
                <Row>
                  <Col className="mb-4 mr-5">
                    <LoaderButton
                      type="submit"
                      disabled={!isValid}
                      isLoading={this.state.isLoading}
                      className="float-right px-5">
                      Login
                      </LoaderButton>
                  </Col>
                  <Col>
                    <Button variant="secondary" onClick={this.handleForgot}>
                      Esqueci minha senha
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.login.isAuthenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAuthenticated: isAuthenticated => dispatch(setAuthenticated({ isAuthenticated }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)