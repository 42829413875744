import React, { useEffect, useState } from 'react'
import { Router, globalHistory } from '@reach/router'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'

import { setAuthenticated } from './redux/loginActions'
import Home from './pages/Home'
import Login from './pages/Login'
import pages from './pages/index'
import forms from './forms/index'
import MainMenu from './components/MainMenu'
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'

console.log('pages loaded', pages);

const NotFound = () => <p>Pagina não encontrada!</p>

const DynamicPage = props => {
  if (pages[props.page]) {
    const C = pages[props.page]
    return <C {...props} />
  } else {
    return <NotFound />
  }
}

const DynamicForm = props => {
  if (forms[props.form]) {
    const C = forms[props.form]
    return <C {...props} />
  } else {
    return <NotFound />
  }
}

function App(props) {
  const [location, setLocation] = useState({})
  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
  }, [])

  const onLoad = async () => {
    setLocation(globalHistory.location)
    globalHistory.listen(({ location, action }) => {
      setLocation(location)
      console.log({ location, action });
    });
    try {
      await Auth.currentSession()
      props.setAuthenticated(true)
    } catch (e) {
      props.setAuthenticated(false)
    }
  }

  const handleLogout = async () => {
    try {
      await Auth.signOut()
      props.setAuthenticated(false)
    } catch (e) {
      console.log('logout error', e)
    }
  }

  if (!props.isAuthenticated) {
    return (<Container fluid className="min-vh-100 bg-dark">
      <Login />
    </Container>)
  }
  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="m-0 p-0">
          <MainMenu location={location} className="position-fixed"
            onLogout={handleLogout} />
        </Col>
        <Col className="pt-5 pl-4">
          <Router>
            <Home path="/" />
            <DynamicPage path="/:page" />
            <DynamicForm path="/form/:form/:id" />
            <NotFound default />
          </Router>
        </Col>
      </Row>
    </Container>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setAuthenticated: isAuthenticated => dispatch(setAuthenticated({ isAuthenticated }))
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.login.isAuthenticated,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
